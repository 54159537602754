// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyD9E-sTKQPmaOKRYMnYf2kKILpJ0RYEXTQ",
  authDomain: "mo-distributor.firebaseapp.com",
  projectId: "mo-distributor",
  storageBucket: "mo-distributor.firebasestorage.app",
  messagingSenderId: "236754368494",
  appId: "1:236754368494:web:155108c33c87628f39c0ff",
  measurementId: "G-JVQX250EZ8"
};

